import Image from 'next/future/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import useCloudPrice from '../hooks/useCloudPrice'
import { useCountryTaxRate } from '../hooks/useCountryTaxRate'

export default function Plugin ({ plugin }) {
  const { t } = useTranslation(['categories', 'common', 'plugins'])
  const { cloudPricePeriod } = useCloudPrice(plugin)
  const router = useRouter()

  const pluginIntro = useMemo(() => {
    const language = router.locale.toUpperCase()
    const description = plugin.descriptions.find(description => description.language === language)

    if (!description) return plugin.descriptions[0].intro

    return description.intro
  })

  const { initializeUserCountry, taxRate } = useCountryTaxRate(plugin)

  useEffect(() => {
    initializeUserCountry()
  }, [])

  return (
    <div className="row plugin">
      <div className="col-12">
        <div className="mb-3 pb-3 border-bottom border-secondary">
          <div className="row justify-content-between align-items-start">
            <div className="col-12 col-md-4 col-lg-2 mb-2 mb-lg-0">
              <Link href={`/plugins/${plugin.slug}`}>
                <a>
                  {plugin.logo && (
                    <Image
                      alt=""
                      className="rounded img-fluid"
                      src={plugin.logo.url}
                      width={plugin.logo.width}
                      height={plugin.logo.height}
                    />
                  )}
                  {
                    !plugin.logo && t('plugins.no_image')
                  }
                </a>
              </Link>
            </div>
            <div className="col-12 col-lg-5">
              <strong>
                {plugin.name}
              </strong>
              <div className="d-flex gap-2 my-2 flex-wrap">
                {
                  plugin.categories.map(category => (
                    <div
                      key={category.id}
                      className="badge text-primary border border-primary rounded-pill"
                    >
                      {t(category.category, { ns: 'categories' })}
                    </div>
                  ))
                }
              </div>
              <p className="mb-0">
                {plugin.descriptions.length > 0 && pluginIntro}
              </p>
            </div>
            <div className="col-12 col-lg-5">
              <div className="d-flex align-items-start flex-column flex-lg-row justify-content-end">
                <div className="fw-bold plugin__price">
                  {
                    (!plugin.is_free && !plugin.is_cloud_product && !plugin.is_on_request_only) && (
                      <div>
                        {

                          t(
                            'price',
                            {
                              ns: 'plugins',
                              price: plugin.starting_price ? plugin.starting_price / 100 : plugin.starting_price
                            }
                          )
                        }
                        {
                          taxRate?.getTaxRate
                            ? (
                              <span className="row fs-6 mb-2">
                                {
                                  t(
                                    'price',
                                    {
                                      ns: 'plugins',
                                      price: plugin.starting_price ? (plugin.starting_price + plugin.starting_price * taxRate?.getTaxRate / 100) / 100 : plugin.starting_price
                                    }
                                  )
                                }
                                {
                                  t('detail.cart.w_tax', { ns: 'plugins' })
                                }
                              </span>)
                            : ''
                        }
                      </div>
                    )
                  }
                  {
                    plugin.is_cloud_product && (
                      <div className="col-12 mb-3">
                        {
                          t('price_with_period', {
                            ns: 'plugins',
                            price: plugin.cloud_starting_price_net / 100,
                            period: cloudPricePeriod
                          })
                        }
                      </div>
                    )
                  }
                  {
                    plugin.is_on_request_only && (
                      <div className="col-12 mb-3">
                        {

                          t(
                            'price',
                            {
                              ns: 'plugins',
                              price: plugin.starting_price ? plugin.starting_price / 100 : plugin.starting_price
                            }
                          )
                        }
                      </div>
                    )
                  }
                  {
                    plugin.is_free && (t('is_free', { ns: 'plugins' })
                    )
                  }
                </div>
                <div className="d-flex flex-column ms-lg-3 mb-3 mb-lg-0 mt-1 mt-lg-0 align-items-end">
                  <Link href={`/plugins/${plugin.slug}`}>
                    <a className="btn btn-primary">
                      {t('to_product', { ns: 'plugins' })}
                    </a>
                  </Link>
                  {
                    plugin.demo_url && (
                      <span className="text-center fs-5 mt-1">
                        {t('demo_available', { ns: 'plugins' })}
                      </span>
                    )
                  }
                  {
                    plugin.is_cloud_product && (
                      <div className="mt-1 text-end">
                        <Link href={`/seller/${plugin.company.slug}?contactSubject=${encodeURIComponent(plugin.name)}#sellerContactForm`}>
                          <a
                            target="_blank"
                            className="d-block fw-normal fs-7"
                          >
                            <span>
                              {t('cloud_product_contact_seller', { ns: 'plugins' })}
                            </span>
                          </a>
                        </Link>
                      </div>
                    )
                  }
                  {
                    plugin.is_on_request_only && (
                      <div className="mt-1 text-end">
                        <Link href={`/seller/${plugin.company.slug}?contactSubject=${encodeURIComponent(plugin.name)}#sellerContactForm`}>
                          <a
                            target="_blank"
                            className="d-block fw-normal fs-7"
                          >
                            <span>
                              {t('contact_seller', { ns: 'plugins' })}
                            </span>
                          </a>
                        </Link>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
