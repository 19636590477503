import { useLazyQuery } from '@apollo/client'
import { GET_USER_COUNTRY } from '../services/graphql/queries/getUserCountry'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import useBackendValidationErrors from '../services/backendvalidationerrors'
import { GET_TAX_RATE } from '../services/graphql/queries/getTaxRate'

export const useCountryTaxRate = (plugin) => {
  const [getCountry, { data: getCountryQueryResult }] = useLazyQuery(GET_USER_COUNTRY, {
    variables: {
      company_id: parseInt(plugin?.company.id)
    },
    onError: ({ graphQLErrors }) => {
      getValidationErrors(graphQLErrors).forEach(error => {
        toast.error(error)
      })
    }
  })
  const [userCountry, setUserCountry] = useState()
  const { getValidationErrors } = useBackendValidationErrors()
  const [getTaxRate, { data: taxRate }] = useLazyQuery(GET_TAX_RATE, {
    variables: {
      input: {
        company_id: parseInt(plugin?.company?.id),
        country_id: userCountry,
        buyer_type: 'Private'
      }
    }
  })

  useEffect(() => {
    if (userCountry) {
      // Store value
      localStorage.setItem('userCountry', userCountry)

      // Get Tax rate
      getTaxRate()
    }
  }, [userCountry])

  useEffect(() => {
    if (!getCountryQueryResult) {
      return
    }

    setUserCountry(getCountryQueryResult.getUserCountry)
  }, [getCountryQueryResult])

  const initializeUserCountry = () => {
    if (typeof localStorage === 'undefined') {
      return
    }

    // Populate with stored value
    if (localStorage.getItem('userCountry')) {
      setUserCountry(localStorage.getItem('userCountry'))

      return
    }

    getCountry()
  }

  return {
    initializeUserCountry,
    taxRate
  }
}
