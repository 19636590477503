import { useMemo } from 'react'
import Alert from '../components/Alert'

// This is currently only used for backend validation errors like extension.validations
export default function useBackendValidationErrors (error) {
  const getValidationErrors = graphQLErrors => {
    const errors = []

    if (!graphQLErrors || !graphQLErrors.length) {
      return errors
    }

    for (const graphQLError of graphQLErrors) {
      if (graphQLError.extensions && graphQLError.extensions.validation) {
        for (const name in graphQLError.extensions.validation) {
          for (const error of graphQLError.extensions.validation[name]) {
            errors.push(error)
          }
        }

        continue
      }

      errors.push(graphQLError.message)
    }

    return errors
  }

  const getValidationErrorsAsAlert = useMemo(() => {
    if (!error) {
      return ''
    }
    return (
      <Alert variant="danger">
        <ul className="m-0">
          {
            getValidationErrors(error.graphQLErrors).map((error, key) => (
              <li key={key}>{error}</li>
            ))
          }
        </ul>
      </Alert>
    )
  })

  return {
    getValidationErrorsAsAlert,
    getValidationErrors
  }
}
