import { useMemo } from 'react'
import { useTranslation } from 'next-i18next'

export default function useCloudPrice (plugin) {
  const { t } = useTranslation(['plugins'])
  const cloudPricePeriodResolver = plugin => {
    if (!plugin.cloud_starting_price_period) {
      return ''
    }

    if (plugin.cloud_starting_price_period === 1) {
      return t('cloud_price_period_month', { ns: 'plugins' })
    }

    return t('cloud_price_period_year', { ns: 'plugins' })
  }

  const cloudPricePeriod = useMemo(() => {
    return cloudPricePeriodResolver(plugin)
  }, [plugin])

  return {
    cloudPricePeriod,
    cloudPricePeriodResolver
  }
}
